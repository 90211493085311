import React from 'react'
import { ContactIframe, ContactCard, ContactContainer, ContactH2, ContactIcon, ContactP, ContactWrapper, ContactH1 } from './ContactElements'
import Icon from '../../images/location.svg';
import Phone from '../../images/phone.svg';


const Contact = () => {
    return (
       < > 
  
       <ContactContainer id="contact">
           <ContactH1>Kontaktieren Sie uns</ContactH1>
<ContactWrapper>


<ContactCard>
     <ContactIcon src={Phone} />
     <ContactH2>Telefon:</ContactH2> 
      <ul>
                                <li>Garage:<br /> <a href="tel:+41795825346">079 582 53 46</a>   </li>
                                <br />
                                <li>Verkauf:<br /><a href="tel:+41793341713">079 334 17 13 </a> </li>
                            </ul>
    </ContactCard>

    
    <ContactCard >
  
        <ContactIframe>
<iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5476.033745861454!2d7.599569!3d46.665928!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3c9231a2860d16a9!2sGarage%20AutoJaro%20GmbH!5e0!3m2!1sde!2sus!4v1604255560061!5m2!1sde!2sus"
             width="100%"
            title="gmapsframe"
             height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            
            />
            </ContactIframe>
            
  

</ContactCard>

    
   

    <ContactCard >
     <ContactIcon src={Icon} />
     <ContactH2>Adresse:</ContactH2> 
     <ContactP>Garage AutoJaro GmbH<br />
Burgholz 52<br />
3753 Oey</ContactP> 
<ContactH2>Email:</ContactH2> 
<ContactP> <a href= "mailto:garage@autojaro.ch">Garage@autojaro.ch</a>
</ContactP> 
    </ContactCard>

    


</ContactWrapper>

       </ContactContainer>
       
     
       </>
    )
}

export default Contact
